import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Media } from 'reactstrap';

class ProductsMenu extends React.Component {

    render() {
        return <UncontrolledDropdown nav>
        <DropdownToggle nav>
          <i className="ni ni-diamond d-lg-none mr-1" />
          <span className="nav-link-inner--text">Products</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-xl">
          <div className="dropdown-menu-inner">
            <Media className="d-flex align-items-center"  href="products#voting" >
              <div className="icon icon-shape bg-gradient-info rounded-circle text-white">
                <i className="ni ni-chart-bar-32" />
              </div>
              <Media body className="ml-3">
                <h6 className="heading text-info mb-md-1">
                  Voting Software
                </h6>
                <p className="description d-none d-md-inline-block mb-0">
                  Organise polls, elections, multichoice questions and many more
                </p>
              </Media>
            </Media>
            <Media className="d-flex align-items-center"  href="products#ffa" >
              <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                <i className="fa fa-cogs" />
              </div>
              <Media body className="ml-3">
                <h6 className="heading text-success mb-md-1">
                  Field Force Automation
                </h6>
                <p className="description d-none d-md-inline-block mb-0">
                  Highly configurable field force automation software for corporations that have field workers
                </p>
              </Media>
            </Media>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
      ;
    }
}

export default ProductsMenu;