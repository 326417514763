import React from 'react';
import "./orbit.css";
import aos from 'aos';
import Logo from '../../assets/img/brand/kanero-logo-white.png';
import Gjlogo from '../../assets/img/brand/g-j-logo.png';
import gmes from '../../assets/img/brand/gmes-africa-logo.png';
import lavar from '../../assets/img/brand/lavar-logo.png';
import pax from '../../assets/img/brand/pax-logo.png';
import ugbs from '../../assets/img/brand/ugbs-logo.png';

const customers = [
    {
        name: 'G & J Technical Services',
        url: 'https://gjtechghana.com',
        logo: Gjlogo,
        color: '',
    },
    {
        name: 'GMES and Africa',
        url: '#',
        logo: gmes,
        color: '',
    },
    {
        name: 'Lavar Laundry Services',
        url: '#',
        logo: lavar,
        color: '',
    },
    {
        name: 'Pax Romana UG Local',
        url: '#',
        logo: pax,
        color: '',
    },
    {
        name: 'University of Ghana Business School',
        url: '#',
        logo: ugbs,
        color: '',
    },
]

class OrbitUsers extends React.Component {

    componentDidMount() {
        aos.init();
    }

    render() {
        return (
            <section className="section-frontpage-integration section-spacing-sm hidden-xs">
                <div className="container">
                    <div className="row vertical-center-flex">
                        <div className="col-md-6 col-sm-4">
                            <img className="frontpage-integration__img-alt img-fluid center-block visible-xs" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="img/frontpage/en/fp_integration_img_alt_ru.png" alt="integration" />
                            <div className="frontpage-integration__systems center-block">
                                <div className="frontpage-integration__main-logo">
                                    <img className="img-fluid" src={Logo} data-src={Logo} alt="Kanero" style={{display: 'block'}} />
                                </div>
                                {
                                    customers.slice(0, 5).map((c, index) => {
                                        return <a key={index} href={c.url} target="_blank" rel='noopener noreferrer' className={`integration-system integration-system--${index} aos-init aos-animate`} data-aos="zoom-in" data-aos-delay="300" data-aos-duration="300" data-aos-offset="200" style={{backgroundColor: c.color}} title={c.name}>
                                            <img className="img-fluid" src={c.logo} data-src={c.logo} alt={`${c.name} logo`} style={{display: 'block', borderRadius: '50%'}} />
                                        </a>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            
                <p className="text-italic">
                    Client Wall-Of-Fame.
                  </p>
            </section >
        );
    }

}

export default OrbitUsers;