import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Media } from 'reactstrap';

class ServicesMenu extends React.Component {

    render() {
        return <UncontrolledDropdown nav>
        <DropdownToggle nav>
          <i className="ni ni-diamond d-lg-none mr-1" />
          <span className="nav-link-inner--text">Services</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-xl">
          <div className="dropdown-menu-inner">
            <Media className="d-flex align-items-center"  href="services#software-development" >
              <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                <i className="ni ni-app" />
              </div>
              <Media body className="ml-3">
                <h6 className="heading text-primary mb-md-1">
                  Software Development
                </h6>
                <p className="description d-none d-md-inline-block mb-0">
                  We create quality, robust and beautiful software applications for you and your business
                </p>
              </Media>
            </Media>
            <Media className="d-flex align-items-center"  href="services#server-maintenance" >
              <div className="icon icon-shape bg-gradient-info rounded-circle text-white">
                <i className="fa fa-server" />
              </div>
              <Media body className="ml-3">
                <h6 className="heading text-info mb-md-1">
                  Server Setup/Maintenance
                </h6>
                <p className="description d-none d-md-inline-block mb-0">
                  We setup your server environment installing and managing all applications on it to ensure high uptime
                </p>
              </Media>
            </Media>
            <Media className="d-flex align-items-center"  href="services#training" >
              <div className="icon icon-shape bg-gradient-danger rounded-circle text-white">
                <i className="ni ni-hat-3" />
              </div>
              <Media body className="ml-3">
                <h6 className="heading text-danger mb-md-1">
                  Software Dev't Training
                </h6>
                <p className="description d-none d-md-inline-block mb-0">
                  High quality training for individuals and institutions on software development
                </p>
              </Media>
            </Media>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
      ;
    }
}

export default ServicesMenu;